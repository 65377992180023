import React from 'react';
import { useInView } from 'react-intersection-observer';
import {
	withScriptjs,
	withGoogleMap,
	GoogleMap,
	Marker
} from 'react-google-maps';

/*
	Map Settings
	Find the Latitude and Longitude of your address:
		- https://www.latlong.net/
		- http://www.findlatitudeandlongitude.com/find-address-from-latitude-and-longitude/
		key AIzaSyAU_autoRByPtjwxjZpw3X8V-Z5D3iOJC0
*/

const mystyles = [
	{
		featureType: 'all',
		elementType: 'labels.text.fill',
		stylers: [{ saturation: 36 }, { color: '#000000' }, { lightness: 40 }]
	},
	{
		featureType: 'all',
		elementType: 'labels.text.stroke',
		stylers: [{ visibility: 'on' }, { color: '#000000' }, { lightness: 16 }]
	},
	{
		featureType: 'all',
		elementType: 'labels.icon',
		stylers: [{ visibility: 'off' }]
	},
	{
		featureType: 'administrative',
		elementType: 'geometry.fill',
		stylers: [{ color: '#000000' }, { lightness: 20 }]
	},
	{
		featureType: 'administrative',
		elementType: 'geometry.stroke',
		stylers: [{ color: '#000000' }, { lightness: 17 }, { weight: 1.2 }]
	},
	{
		featureType: 'landscape',
		elementType: 'geometry',
		stylers: [{ color: '#000000' }, { lightness: 20 }]
	},
	{
		featureType: 'poi',
		elementType: 'geometry',
		stylers: [{ color: '#000000' }, { lightness: 21 }]
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry.fill',
		stylers: [{ color: '#000000' }, { lightness: 17 }]
	},
	{
		featureType: 'road.highway',
		elementType: 'geometry.stroke',
		stylers: [{ color: '#000000' }, { lightness: 29 }, { weight: 0.2 }]
	},
	{
		featureType: 'road.arterial',
		elementType: 'geometry',
		stylers: [{ color: '#000000' }, { lightness: 18 }]
	},
	{
		featureType: 'road.local',
		elementType: 'geometry',
		stylers: [{ color: '#000000' }, { lightness: 16 }]
	},
	{
		featureType: 'transit',
		elementType: 'geometry',
		stylers: [{ color: '#000000' }, { lightness: 19 }]
	},
	{
		featureType: 'water',
		elementType: 'geometry',
		stylers: [{ color: '#000000' }, { lightness: 17 }]
	}
];
const MyMapComponent = withScriptjs(
	withGoogleMap(props => (
		<GoogleMap
			defaultZoom={12}
			defaultCenter={{ lat: 50.128340, lng: 14.502062  }}
			defaultOptions={{ styles: mystyles }}
		>
			<Marker position={{ lat: 50.128340, lng: 14.502062 }} />
		</GoogleMap>
	))
);

const Footer = ({ tel, email }) => {
	const [ref, inView] = useInView({
		/* Optional options */
		threshold: 0,
		triggerOnce: true
	});

	return (
		<footer id="footer" ref={ref}>
			<section id="kontakt">
				<div className="container">
					<div className="row justify-content-center py-4 text-center">
						<div className="col-12">
							<span className="cursor-pointer text-uppercase font-weight-bold text-3 text-color-light">
								<i className="fas fa-map" /> Působíme NONSTOP v celé
								Praze a okolí
							</span>
						</div>
					</div>
					<div className="row py-4 text-center">
						<div className="col-12 col-md-6">
							<h3>FAKTURAČNÍ UDAJE</h3>
							<p className="text-white">
								Zámkařství Praha s.r.o.
								<br />
								Chotěšovská 680/1
								<br />
								Praha 18, Letňany
								<br />
								IČO: 05591121
								<br />
								Bankovní účet číslo:
								<br />
								43274327/5500
								<br />
								Vedený u banky Raiffeisenbank a.s.
							</p>
						</div>
						<div className="col-12 col-md-6">
							<h3>KONTAKNÍ ÚDAJE</h3>
							<p className="text-white">
								mobil: <a className="text-primary" href={'tel:+420' + tel}>(+420) <strong>{tel}</strong></a>
								<br />
								e-mail: <a className="text-primary" href={'mailto:' + email}><strong>{email}</strong></a>
								<br />
								e-mail 2:{' '}
								<a className="text-primary" href={'mailto:zamkarstvipraha@gmail.com'}>
								<strong>zamkarstvipraha@gmail.com</strong>
								</a>
							</p>
						</div>
					</div>
				</div>
				{inView && (
					<div>
						<MyMapComponent
							googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAU_autoRByPtjwxjZpw3X8V-Z5D3iOJC0&v=3.exp&libraries=geometry,drawing,places"
							loadingElement={<div style={{ height: `100%` }} />}
							containerElement={<div style={{ height: `400px` }} />}
							mapElement={<div style={{ height: `100%` }} />}
						/>
					</div>
				)}
			</section>
			<div className="footer-copyright footer-copyright-style-2">
				<div className="container py-2">
					<div className="row py-4">
						<div className="col-md-4 d-flex align-items-center justify-content-center justify-content-md-start mb-2 mb-lg-0">
							<p className="text-white">
								© Copyright {new Date().getFullYear()}. Všechna práva
								vyhrazena.
							</p>
						</div>
						<div className="col-md-8 d-flex align-items-center justify-content-center justify-content-md-end mb-4 mb-lg-0">
							<p className="text-white">
								<a
									href={'mailto:' + email}
									className="opacity-7 pl-1 text-white mr-2"
								>
									<i className="far fa-envelope top-1 p-relative" />{' '}
									{email}
								</a>
								<a
									href={'tel:+420' + tel}
									className="opacity-7 pl-1 text-white "
								>
									<i className="fab fa-whatsapp top-1" />
									<span className="pl-1">(+420) <strong>{tel}</strong></span>
								</a>
							</p>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
