/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import Header from '../Header/header';
import Footer from '../Footer/footer';

import '../../scss/default.scss';

const Layout = ({ children, description, lang, meta, title, ogImage }) => {
	const { site, seoImage } = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					title
					description
					author
					lang
					email
					tel
					siteUrl
				}
			}
			seoImage: file(relativePath: { eq: "favicon.png" }) {
				childImageSharp {
					fixed(width: 322, height: 322) {
						...GatsbyImageSharpFixed
					}
				}
			}
		}
	`);
	const metaTitle = title || site.siteMetadata.title;
	const metaDescription = description || site.siteMetadata.description;
	const metaLang = lang || site.siteMetadata.lang;	
	const metaImage = ogImage || seoImage.childImageSharp.fixed.src;

	return (
		<>
			<Helmet
				htmlAttributes={{
					lang: metaLang
				}}
				lang={metaLang}
				charSet='UTF-8'
				title={title}
				defaultTitle={site.siteMetadata.title}
				titleTemplate={`%s | ${site.siteMetadata.title}}`}
				meta={[
					{
						name: `viewport`,
						content: `width=device-width, initial-scale=1`
					},
					{
						name: `robots`,
						content: `index,follow`
					},
					{
						name: `googlebot`,
						content: `index,follow`
					},
					{
						property: `og:image`,
						content: site.siteMetadata.siteUrl + metaImage
					},
					{
						name: `description`,
						content: metaDescription
					},
					{
						property: `og:title`,
						content: metaTitle
					},
					{
						property: `og:description`,
						content: metaDescription
					},
					{
						property: `og:type`,
						content: `website`
					},
					{
						name: `twitter:card`,
						content: `summary`
					},
					{
						name: `twitter:creator`,
						content: site.siteMetadata.author
					},
					{
						name: `twitter:title`,
						content: title
					},
					{
						name: `twitter:description`,
						content: metaDescription
					}
				].concat(meta)}
			></Helmet>
			<div className="body">			
				<Header email={site.siteMetadata.email} tel={site.siteMetadata.tel} />
				<main>{children}</main>
				<Footer email={site.siteMetadata.email} tel={site.siteMetadata.tel} />
			</div>
		</>
	);
};

Layout.defaultProps = {
	lang: `cs-CZ`,
	meta: [],
	description: ``
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string
};
export default Layout;


