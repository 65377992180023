import React from 'react';
// import { Link } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll'

// import css from './navbar.module.scss';

const Navbar = () => {
	return (
		<nav className="header-nav p-0 justify-content-center align-items-stretch">
			<ul className="nav " id="mainNav">
				<li >
					<AnchorLink className="nav-link" href='#sluzby'><strong>Služby</strong></AnchorLink>
				</li>
				<li >
					<AnchorLink className="nav-link" href='#reference'><strong>Reference</strong></AnchorLink>
				</li>
				<li >
					<AnchorLink className="nav-link" href='#cenik'><strong>Ceny</strong></AnchorLink>
				</li>
				<li >
					<AnchorLink className="nav-link" href='#kontakt'><strong>Kontakt</strong></AnchorLink>
				</li>
			</ul>
		</nav>
	);
};

export default Navbar;
