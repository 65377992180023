// import { Link } from "gatsby"
import React from 'react';
import Navbar from '../Navbar/navbar';
import Logo from '../../images/zamkarstvi-praha-logo.inline.svg';
import { Link } from 'gatsby';

import css from './header.module.scss';

// implement https://www.npmjs.com/package/react-anchor-link-smooth-scroll for scrolling

const Header = ({tel, email}) => (
	<header id='header'>
		<div className='header-body border-top-0'>
			<div className='header-top header-top-default border-bottom-0 bg-color-secondary'>
				<div className='container'>
					<div className='header-row py-2'>
						<div className='header-column justify-content-start'>
							<div className='header-row'>
								<div className='text-light text-2'>
									Přijímáme platební karty
								</div>
							</div>
						</div>
						{/* <div className="header-column justify-content-end">
							<div className="header-row">
								<div className="header-nav-features header-nav-features-no-border">
									<div className="header-nav-feature header-nav-features-cart d-inline-flex ml-2">
										<a href="#" className="header-nav-features-toggle">
											cart
											<span className="cart-info d-none">
												<span className="cart-qty">1</span>
											</span>
										</a>
										<div className="header-nav-features-dropdown" id="headerTopCartDropdown">
											<ol className="mini-products-list">
												<li className="item">
													<div className="product-details">
														<p className="product-name">
															<a href="#">Camera X1000 </a>
														</p>
														<p className="qty-price">
															1X <span className="price">$890</span>
														</p>
														<a href="#" title="Remove This Item" className="btn-remove">
															<i className="fas fa-times" />
														</a>
													</div>
												</li>
											</ol>
											<div className="totals">
												<span className="label">Total:</span>
												<span className="price-total">
													<span className="price">$890</span>
												</span>
											</div>
											<div className="actions">
												<a className="btn btn-dark" href="#">
													View Cart
												</a>
												<a className="btn btn-primary" href="#">
													Checkout
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div> */}
					</div>
				</div>
			</div>
			<div className='header-container container'>
				<div className='header-row'>
					<div className='header-column justify-content-start w-50 order-md-1 d-none d-md-flex'>
						<div className='header-row'>
							<ul className='header-extra-info'>
								<li className='m-0'>
									<div className='feature-box feature-box-style-2 align-items-center'>
										<div className='feature-box-info'>
											<p className='pb-0 font-weight-semibold line-height-5 text-2'>
												Po - Ne: <strong className="text-4">NONSTOP</strong>
												<br />
												Průměrný dojezd <strong className="text-4">20 minut</strong>
											</p>
										</div>
									</div>
								</li>
							</ul>
						</div>
					</div>
					<div className='header-column justify-content-start justify-content-md-center order-1 order-md-2'>
						<div className='header-row'>
							<div className='header-logo'>
								<Link to='/' title='Zámkařství Praha Logo'>
									<Logo className={css.logo} />
								</Link>
							</div>
						</div>
					</div>
					<div className='header-column justify-content-end w-50 order-2 order-md-3'>
						<div className='header-row'>
							<ul className='header-extra-info justify-content-end'>
								<li className='m-0 d-block d-sm-none'>
									<div className='feature-box reverse-allres feature-box-style-2 align-items-center'>
										<div className='feature-box-info'>
											<p className='pb-0 font-weight-semibold line-height-5 text-3'>
												Po - Ne: <strong>NONSTOP</strong>
												<br />
												Průměrný dojezd<br /> <strong>20 minut</strong>
											</p>
										</div>
									</div>
								</li>
								<li className='m-0 d-block'>
									<div className='feature-box reverse-allres feature-box-style-2 align-items-center'>
										<div className='feature-box-icon'>
											<a href={'tel:+420' + tel}>
												<i
													className='fab fa-whatsapp text-7 p-relative'
													style={{ top: '-2px' }}
												/>
											</a>
										</div>
										<div className='feature-box-info'>
											<p className='pb-0 font-weight-semibold line-height-5 text-3'>
												<a href={'tel:+420' + tel}>
													(+420) <strong style={{fontSize: '1.25em'}}>{tel}</strong>
												</a>
											</p>
										</div>
									</div>
								</li>
								<li className='m-0 d-block'>
									<div className='feature-box reverse-allres feature-box-style-2 align-items-center'>
										<div className='feature-box-icon'>
											<i className='far fa-envelope text-5 p-relative' />
										</div>
										<div className='feature-box-info'>
											<p className='pb-0 font-weight-semibold line-height-5 text-3'>
												<a href={'mailto:' + email}>
													{email}
												</a>
											</p>
										</div>
									</div>
								</li>								
								
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className='header-nav-bar border-top'>
				<div className='header-container container'>
					<div className='header-row'>
						<div className='header-column'>
							<div className='header-row justify-content-center'>
								<Navbar />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
);

export default Header;
